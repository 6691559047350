<template>
  <div id="container">
    <a-row class="maps-btn">
      <a-col :span="8">
        <a-button
          :class="isShowBattery ? 'btn-active' : 'btn-default'"
          @click="changeState('battery')"
          >电池</a-button
        >
      </a-col>
      <a-col :span="8">
        <a-button
          :class="isShowChargeCabinet ? 'btn-active' : 'btn-default'"
          @click="changeState('chargeCabinet')"
          >换电柜</a-button
        >
      </a-col>
      <a-col :span="8">
        <a-button
          :class="isShowPoint ? 'btn-active' : 'btn-default'"
          @click="changeState('point')"
          >租赁点</a-button
        >
      </a-col>
    </a-row>
    <div class="info" style="display: none">
      <div ref="batteryInfo" class="info-box">
        <div @click="this.closeInfoWindow" class="closeIcon">X</div>
        <ul>
          <li>电池编号:{{ batteryData.deviceId }}</li>
          <li>电池品牌:动宇</li>
          <li>电池型号:72V60AH</li>
          <li>在线状态:在线</li>
          <li>电池电量:{{ batteryData.soc }}%</li>
          <li>业务状态:站点库存</li>
          <li>定位时间:{{ batteryData.gpsTime }}</li>
          <li>数据时间:{{ batteryData.heartTime }}</li>
        </ul>
        <span class="btn" @click="batteryWatch">电池监控</span>
        <div class="amap-info-sharp"></div>
      </div>
      <div ref="chargeCabinetInfo" class="info-box">
        <div @click="this.closeInfoWindow" class="closeIcon">X</div>
        <ul>
          <li>换电柜名称:{{ chargeCabinetData.cabinetName }}</li>
          <li>换电柜编号:{{ chargeCabinetData.cabinetCode }}</li>
          <li>换电柜厂家:{{ chargeCabinetData.brand }}</li>
          <li>换电柜型号:{{ chargeCabinetData.model }}</li>
          <li>在柜电池数:{{ chargeCabinetData.chargingCount }}</li>
          <li>
            换电柜地址:<span class="text2">{{
              chargeCabinetData.address
            }}</span>
          </li>
          <li>
            换电柜状态:{{
              chargeCabinetData.state === 'using'
                ? '租赁中'
                : chargeCabinetData.state === 'repair'
                ? '维修中'
                : chargeCabinetData.state === 'stock'
                ? '库存'
                : ''
            }}
          </li>
          <li>
            <span class="text1">在 线 状 态:</span>
            {{
              chargeCabinetData.onlineState === '0'
                ? '离线'
                : chargeCabinetData.onlineState === '1'
                ? '在线'
                : ''
            }}
          </li>
          <li>
            <span class="text1">数 据 时 间:</span
            >{{ chargeCabinetData.updateTime }}
          </li>
        </ul>
        <span
          class="btn"
          @click="
            this.$router.push({
              path: '/zulin/assets/real-time-monitoring',
              query: { cabinetCode: chargeCabinetData.cabinetCode }
            })
          "
          >数据监控</span
        >
        <div class="amap-info-sharp"></div>
      </div>
      <div ref="pointInfo" class="info-box">
        <div @click="this.closeInfoWindow" class="closeIcon">X</div>
        <ul>
          <li>租赁点名称:{{ pointData.pointName }}</li>
          <li>
            租赁点地址:<span class="text2">{{ pointData.address }}</span>
          </li>
          <li>
            所属运营商:<span class="text2">{{ pointData.operatorName }}</span>
          </li>
        </ul>
        <div class="amap-info-sharp"></div>
      </div>
      <div ref="clusterInfo" class="info-box">
        <div @click="this.closeInfoWindow" class="closeIcon">X</div>
        <ul>
          <li>
            <span class="text1"
              >电 池 数 量:{{ clustersData.batteryCount }}</span
            >
          </li>
          <li>换电柜数量:{{ clustersData.chargeCabinetCount }}</li>
          <li>租赁点数量:{{ clustersData.pointCount }}</li>
          <li>聚合点数量:{{ clustersData.clusterCount }}</li>
        </ul>
        <div class="amap-info-sharp"></div>
      </div>
      <div ref="rightMenuInfo" class="info-box-right">
        <div @click="this.closeRightMenu" class="closeIcon">X</div>
        <ul>
          <li @click="this.zoomOutMap">缩小一级</li>
          <li @click="this.zoomInMap">放大一级</li>
          <li @click="this.setZoomAndCenter">缩放至全国范围</li>
        </ul>
      </div>
    </div>
    <div>
      <a-switch
        checked-children="开"
        un-checked-children="关"
        default-checked
        :checked="checked"
        class="switch"
        @change="onSwitchChange"
      />
    </div>
    <a-modal
      title="电池监控"
      v-model:visible="showBatteryWatch"
      centered
      :width="1000"
      :footer="null"
    >
      <a-row type="flex" justify="space-between" style="margin-bottom: 15px">
        <a-col>
          <span class="text-title">电池编码:{{ batteryData.deviceId }}</span>
        </a-col>
        <a-col>
          <span>更新时间:{{ batteryData.updateTime }}</span>
        </a-col>
      </a-row>
      <a-row
        type="flex"
        justify="space-around"
        :gutter="16"
        style="margin-bottom: 15px"
      >
        <a-col>
          <battery :num="batteryData.soc" :isShowAnimation="true"></battery>
        </a-col>
        <a-col>
          <div class="name">soc</div>
          <div class="value">{{ batteryData.soc }}</div>
        </a-col>
        <a-col>
          <div class="name">电池状态</div>
          <div class="value">
            {{
              batteryData.workStatus === '0'
                ? '放电中'
                : batteryData.workStatus === '1'
                ? '充电中'
                : '闲置'
            }}
          </div>
        </a-col>
        <a-col>
          <div class="name">告警状态</div>
          <div class="value" v-if="batteryData.bmsAlarmList === '0'">----</div>
          <div class="value" v-else>{{ batteryData.bmsAlarmList }}</div>
        </a-col>
        <a-col>
          <div class="name">GMS信号</div>
          <div class="value">100</div>
        </a-col>
        <a-col>
          <div class="name">GMS里程</div>
          <div class="value">{{ Number(batteryData.miles).toFixed(2) }}KM</div>
        </a-col>
        <a-col>
          <div class="name">速度</div>
          <div class="value">{{ batteryData.speed }}KM/h</div>
        </a-col>
      </a-row>
      <a-row
        type="flex"
        justify="space-around"
        :gutter="16"
        style="margin-bottom: 15px"
      >
        <a-col>
          <div class="name">单充保护电压</div>
          <div class="value" v-if="batteryData.cpbv">
            {{ batteryData.cpbv }}mV
          </div>
        </a-col>
        <a-col>
          <div class="name">充电开关</div>
          <div class="value">
            {{ batteryData.chon === '0' ? '关闭' : '开启' }}
          </div>
        </a-col>
        <a-col>
          <div class="name">电池类型</div>
          <div class="value">
            {{ batteryData.batteryType === '0' ? '三元' : '钛锂' }}
          </div>
        </a-col>
        <a-col>
          <div class="name">mos温度</div>
          <div class="value" v-if="batteryData.tcT">
            {{ batteryData.tcT }}&#8451;
          </div>
        </a-col>
        <a-col>
          <div class="name">单放电保护电压</div>
          <div class="value" v-if="batteryData.dpbv">
            {{ batteryData.dpbv }}mV
          </div>
        </a-col>
        <a-col>
          <div class="name">放电开关</div>
          <div class="value">
            {{ batteryData.dhon === '0' ? '关闭' : '开启' }}
          </div>
        </a-col>
        <a-col>
          <div class="name">电池串数</div>
          <div class="value" v-if="batteryData.bcst">
            {{ batteryData.bcst }}串
          </div>
        </a-col>
      </a-row>
      <a-row
        type="flex"
        justify="space-around"
        :gutter="16"
        style="margin-bottom: 15px"
      >
        <a-col>
          <div class="name">电池温度1</div>
          <div class="value" v-if="batteryData.tcB1">
            {{ batteryData.tcB1 }}&#8451;
          </div>
        </a-col>
        <a-col>
          <div class="name">单充电恢复电压</div>
          <div class="value" v-if="batteryData.srbv">
            {{ batteryData.srbv }}mV
          </div>
        </a-col>
        <a-col>
          <div class="name">充电保护</div>
          <div class="value">
            {{
              batteryData.cpStatus === '0'
                ? '正常'
                : batteryData.cpStatus === '1'
                ? '硬控保护'
                : '软控保护'
            }}
          </div>
        </a-col>
        <a-col>
          <div class="name">循环次数</div>
          <div class="value" v-if="batteryData.bxhc">
            {{ batteryData.bxhc }}次
          </div>
        </a-col>
        <a-col>
          <div class="name">电池温度2</div>
          <div class="value" v-if="batteryData.tcB2">
            {{ batteryData.tcB2 }}&#8451;
          </div>
        </a-col>
        <a-col>
          <div class="name">单放电恢复电压</div>
          <div class="value" v-if="batteryData.drbv">
            {{ batteryData.drbv }}mV
          </div>
        </a-col>
        <a-col>
          <div class="name">放电保护</div>
          <div class="value">
            {{
              batteryData.dpStatus === '0'
                ? '正常'
                : batteryData.cpStatus === '1'
                ? '硬控保护'
                : '软控保护'
            }}
          </div>
        </a-col>
      </a-row>
      <a-row
        type="flex"
        justify="space-around"
        :gutter="16"
        style="margin-bottom: 15px"
      >
        <a-col>
          <div class="name">标称容量</div>
          <div class="value" v-if="batteryData.bcrl">
            {{ batteryData.bcrl }}Ah
          </div>
        </a-col>
        <a-col>
          <div class="name">ext温度1</div>
          <div class="value" v-if="batteryData.tcExt1">
            {{ batteryData.tcExt1 }}&#8451;
          </div>
        </a-col>
        <a-col>
          <div class="name">总充电保护电压</div>
          <div class="value" v-if="batteryData.acpbv">
            {{ batteryData.acpbv }}V
          </div>
        </a-col>
        <a-col>
          <div class="name">短路保护</div>
          <div class="value">
            {{ batteryData.scProtect === '0' ? '正常' : '保护' }}
          </div>
        </a-col>
        <a-col>
          <div class="name">放电电流</div>
          <div class="value" v-if="batteryData.tcB2">
            {{ batteryData.dPowerA }}A
          </div>
        </a-col>
        <a-col>
          <div class="name">ext温度2</div>
          <div class="value" v-if="batteryData.txExt2">
            {{ batteryData.txExt2 }}&#8451;
          </div>
        </a-col>
        <a-col>
          <div class="name">总放电保护电压</div>
          <div class="value" v-if="batteryData.adpbv">
            {{ batteryData.adpbv }}V
          </div>
        </a-col>
      </a-row>
      <a-row
        type="flex"
        :gutter="16"
        justify="space-around"
        style="margin-bottom: 15px"
      >
        <a-col>
          <div class="name">均衡状态</div>
          <div class="value" v-if="batteryData.balanceState">
            {{ batteryData.balanceState }}
          </div>
        </a-col>
        <a-col>
          <div class="name">充电电流</div>
          <div class="value" v-if="batteryData.cPowerA">
            {{ batteryData.cPowerA }}A
          </div>
        </a-col>
        <a-col>
          <div class="name">均衡类型</div>
          <div class="value">
            {{
              batteryData.balanceType === '0'
                ? '无均衡'
                : batteryData.balanceType === '1'
                ? '被动'
                : '主动'
            }}
          </div>
        </a-col>
        <a-col>
          <div class="name">总充电恢复电压</div>
          <div class="value" v-if="batteryData.asrbv">
            {{ batteryData.asrbv }}V
          </div>
        </a-col>
        <a-col :span="2"></a-col>
        <a-col :span="2"></a-col>
        <a-col :span="2"></a-col>
      </a-row>
      <a-row type="flex" justify="space-between" style="margin-bottom: 15px">
        <a-col>
          <span class="text-title">电池单体电压</span>
        </a-col>
        <a-col>
          <span class="extremum"></span>
          <span>极值</span>
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="[16, 16]">
        <a-col v-for="(item, index) in vdcList" :key="index" :span="4">
          <div style="display: flex">
            <div
              class="battery-index"
              :style="'color:' + (item.state === '1' ? '#0060aa' : 'green')"
            >
              <span v-if="index < 9">{{ '0' + (index + 1) }}.</span>
              <span v-else>{{ index + 1 }}.</span>
            </div>
            <battery
              size="small"
              name="vdc2"
              :vdc2="item"
              :backgrounds="item.state === '1' ? '#0060aa' : 'green'"
            />
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import AMap from 'AMap'
import * as chargeCabinetApi from '@/api/rnt/chargeCabinet'
import * as pointApi from '@/api/rnt/point'
import * as batteryGpsLastApi from '@/api/rnt/batteryGpsLast.js'
import * as batteryBmsApi from '@/api/rnt/batteryBms.js'
import battery from '@/components/battery/index.vue'
// import Battery from '../assets/battery.vue'
let map
var batteryMarker = []
var chargeCabinetMarker = []
var pointMarker = []
const infoWindow = new AMap.InfoWindow({
  offset: new AMap.Pixel(0, -30),
  isCustom: true
})
var contextMenu
var cluster
var _renderClusterMarker = function (context) {
  var div = document.createElement('div')
  div.style.width = '32px'
  div.style.height = '32px'
  // div.style.backgroundColor = 'white'
  div.style.color = 'red'
  div.style.fontSize = '15px'
  div.style.fontWeight = '600'
  // div.style.borderRadius = '50%'
  div.innerHTML = context.count
  div.style.textAlign = 'center'
  div.style.lineHeight = '32px'
  div.style.backgroundImage = 'url("/static/cluster.png")'
  div.style.backgroundSize = 'contain'
  context.marker.setContent(div)
}
export default {
  name: 'assetMaps',
  components: {
    battery
  },
  data() {
    return {
      // vdcList: [
      //   { id: '01', value: 4.15, state: '1' },
      //   { id: '04', value: 4.145, state: '0' },
      //   { id: '25', value: 4.85, state: '1' },
      //   { id: '06', value: 4.156, state: '1' },
      //   { id: '07', value: 4.25, state: '1' },
      //   { id: '08', value: 4.75, state: '1' },
      //   { id: '15', value: 4.35, state: '1' },
      //   { id: '01', value: 4.15, state: '1' },
      //   { id: '04', value: 4.145, state: '1' },
      //   { id: '25', value: 4.85, state: '1' },
      //   { id: '06', value: 4.156, state: '1' },
      //   { id: '07', value: 4.25, state: '1' },
      //   { id: '08', value: 4.75, state: '1' },
      //   { id: '15', value: 4.35, state: '1' }
      // ],
      showBatteryWatch: false,
      isShowBattery: true,
      isShowChargeCabinet: true,
      isShowPoint: true,
      isShowBatteryInfo: false,
      isShowPointInfo: false,
      isShowChargeCabinetInfo: false,
      isShowClusterInfo: false,
      checked: true,
      // chargeCabinetList: [],
      // pointList: [],
      batteryData: {},
      chargeCabinetData: {},
      pointData: {},
      clustersData: {}
    }
  },
  mounted() {
    this.getBatteryData()
    this.getChargeCabinetData()
    this.getPointData()
    this.init()
    // window.closeInfoWindow = this.closeInfoWindow
  },
  computed: {
    vdcList() {
      if (!this.batteryData.voltageValue) {
        return []
      }
      return this.batteryData.voltageValue.split('|').map((item) => {
        return (Number(item) / 1000).toFixed(3)
      })
    }
  },
  methods: {
    batteryWatch() {
      this.showBatteryWatch = true
    },
    // 创建右键菜单
    contextMenu() {
      const content = this.$refs.rightMenuInfo
      // console.log(content)
      contextMenu = new AMap.ContextMenu({
        isCustom: true,
        content: content
      })
    },
    // 关闭右键菜单
    closeRightMenu() {
      contextMenu.close()
    },
    // 缩放地图
    zoomOutMap() {
      map.zoomOut()
    },
    // 放大地图
    zoomInMap() {
      map.zoomIn()
    },
    // 缩放至全国地图
    setZoomAndCenter() {
      map.setZoomAndCenter(4, [108.946609, 34.262324])
    },
    // 监听switch 开关状态
    onSwitchChange(checked) {
      this.checked = checked
      this.resetMapData()
    },
    // 初始化地图数据
    async init() {
      const that = this
      map = new AMap.Map('container', {
        center: [118.08923, 24.479406],
        resizeEnable: true,
        zoom: 10
      })

      AMap.plugin(['AMap.ToolBar', 'AMap.Scale'], function () {
        map.addControl(new AMap.ToolBar())
        map.addControl(new AMap.Scale())
      })

      const clusterData = await this.resetMapData(true)
      map.plugin(['AMap.MarkerClusterer'], function () {
        that.reSetCluster(clusterData)
        // cluster.on('click', ({ cluster, lnglat, target, markers }) => {
        //   console.log(cluster, lnglat, target, markers)
        //   console.log(cluster, target, markers)
        //   that.clustersData.clusterCount = cluster.lb.length
        //   that.clustersData.batteryCount = that.findTypeList(markers, 'battery')
        //   that.clustersData.chargeCabinetCount = that.findTypeList(
        //     markers,
        //     'chargeCabinet'
        //   )
        //   that.clustersData.pointCount = that.findTypeList(markers, 'point')
        //   // console.log(
        //   //   that.clustersData.clusterCount,
        //   //   that.clustersData.batteryCount,
        //   //   that.clustersData.chargeCabinetCount,
        //   //   that.clustersData.pointCount
        //   // )
        //   that.infoWindowOpen(lnglat, 'cluster')
        // })
      })
      // 监听map zoom 是否改变 然后创建不同的图标
      map.on('zoomchange', this.mapZoom)
      this.contextMenu()
      map.on('rightclick', (e) => {
        contextMenu.open(map, e.lnglat)
      })
    },
    // 找出聚合点位中的同一类型并返回个数
    findTypeList(data, type) {
      var count = 0
      data.forEach((item) => {
        if (item.De.extData.type === type) {
          count++
        }
      })
      return count
    },

    // 监听按钮点击是否显示点标记状态
    changeState(name) {
      if (name === 'battery') {
        this.isShowBattery = !this.isShowBattery
      } else if (name === 'chargeCabinet') {
        this.isShowChargeCabinet = !this.isShowChargeCabinet
      } else if (name === 'point') {
        this.isShowPoint = !this.isShowPoint
      }
      this.resetMapData()
    },
    // map data
    async resetMapData(noCluster) {
      const zoom = map.getZoom()
      // 后台获取电池点位
      const batteryList = await this.getBatteryData()
      const chargeCabinetList = await this.getChargeCabinetData()
      const pointList = await this.getPointData()
      const chargeCabinetlnglats = chargeCabinetList
      const pointlnglats = pointList
      const batterylnglats = batteryList
      // console.log({ pointlnglats, batterylnglats, chargeCabinetlnglats })
      //
      batteryMarker = []
      if (this.isShowBattery) {
        batterylnglats.forEach((item) => {
          const marker = new AMap.Marker({
            position: item.lnglat,
            icon: this.buildIcon('batteryIcon', zoom),
            extData: {
              type: 'battery'
            }
          })
          marker.on('click', (e) => {
            this.infoWindowOpen(e, 'battery')
            this.getBatteryBmsInfo(item.data.deviceId)
          })
          if (this.checked) {
            marker.on('mouseover', (e) => {
              this.infoWindowOpen(e, 'battery')
              this.getBatteryBmsInfo(item.data.deviceId)
            })
            marker.on('mouseout', this.closeInfoWindow)
          }
          batteryMarker.push(marker)
        })
      }
      //
      chargeCabinetMarker = []
      if (this.isShowChargeCabinet) {
        chargeCabinetlnglats.forEach((item) => {
          const marker = new AMap.Marker({
            position: item.lnglat,
            icon: this.buildIcon('chargeCabinetIcon', zoom),
            extData: {
              type: 'chargeCabinet'
            }
          })
          marker.on('click', (e) => {
            this.infoWindowOpen(e, 'chargeCabinet')
            this.chargeCabinetData = item.data
            // await this.getMarkerInfoById('charge', item.id)
          })
          if (this.checked) {
            marker.on('mouseover', async (e) => {
              // await this.getMarkerInfoById('charge', item.id)
              this.chargeCabinetData = item.data
              this.infoWindowOpen(e, 'chargeCabinet')
            })
            marker.on('mouseout', this.closeInfoWindow)
          }
          chargeCabinetMarker.push(marker)
        })
      }
      //
      pointMarker = []
      if (this.isShowPoint) {
        pointlnglats.forEach((item) => {
          const marker = new AMap.Marker({
            position: item.lnglat,
            // map: map,
            icon: this.buildIcon('pointIcon', zoom),
            extData: {
              type: 'point'
            }
          })
          marker.on('click', (e) => {
            this.infoWindowOpen(e, 'point')
            this.pointData = item.data
            // await this.getMarkerInfoById('point', item.id)
          })
          if (this.checked) {
            marker.on('mouseover', (e) => {
              this.infoWindowOpen(e, 'point')
              this.pointData = item.data
              // await this.getMarkerInfoById('point', item.id)
            })
            marker.on('mouseout', this.closeInfoWindow)
          }
          pointMarker.push(marker)
        })
      }

      let clusterData = []
      if (batteryMarker) {
        clusterData = clusterData.concat(batteryMarker)
      }

      if (chargeCabinetMarker) {
        clusterData = clusterData.concat(chargeCabinetMarker)
      }

      if (pointMarker) {
        clusterData = clusterData.concat(pointMarker)
      }

      if (noCluster === true) {
        return clusterData
      }
      this.reSetCluster(clusterData)
    },
    reSetCluster(data) {
      if (cluster) {
        cluster.setMap(null)
        cluster.setMap(map)
        cluster.setMarkers(data)
        return false
      }
      cluster = new AMap.MarkerClusterer(
        map, // 地图实例
        data, // 海量点组成的数组
        {
          gridSize: 30,
          renderClusterMarker: _renderClusterMarker,
          zoomOnClick: true,
          maxZoom: 17
        }
      )
    },
    // 点击标记点或移入标记点获取点位基本信息
    // async getMarkerInfoById(name, id) {
    //   if (name === 'charge') {
    //     await chargeCabinetApi
    //       .info(id)
    //       .then((res) => {
    //         if (res.code === 0) {
    //           this.chargeCabinetData = res.data
    //         } else {
    //           this.$message.error(res.msg)
    //         }
    //       })
    //       .catch((e) => {
    //         this.$message.error(e.message)
    //       })
    //   }
    //   if (name === 'battery') {
    //     await chargeCabinetApi
    //       .info(id)
    //       .then((res) => {
    //         if (res.code === 0) {
    //           this.chargeCabinetData = res.data
    //         } else {
    //           this.$message.error(res.msg)
    //         }
    //       })
    //       .catch((e) => {
    //         this.$message.error(e.message)
    //       })
    //   }
    //   if (name === 'point') {
    //     await pointApi
    //       .info(id)
    //       .then((res) => {
    //         if (res.code === 0) {
    //           this.pointData = res.data
    //         } else {
    //           this.$message.error(res.msg)
    //         }
    //       })
    //       .catch((e) => {
    //         this.$message.error(e.message)
    //       })
    //   }
    // },
    // 获取信息窗口数据
    async getBatteryBmsInfo(id) {
      batteryBmsApi
        .page({ deviceId: id })
        .then((res) => {
          if (res.code === 0) {
            this.batteryData = res.data[0]
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 信息窗口打开
    infoWindowOpen(e, name) {
      if (name === 'battery') {
        const sContent = this.$refs.batteryInfo
        infoWindow.setContent(sContent)
        infoWindow.open(map, e.target.getPosition())
      } else if (name === 'chargeCabinet') {
        const sContent = this.$refs.chargeCabinetInfo
        // 后台数据
        infoWindow.setContent(sContent)
        infoWindow.open(map, e.target.getPosition())
      } else if (name === 'point') {
        const sContent = this.$refs.pointInfo
        infoWindow.setContent(sContent)
        infoWindow.open(map, e.target.getPosition())
      } else if (name === 'cluster') {
        const sContent = this.$refs.clusterInfo
        infoWindow.setContent(sContent)
        infoWindow.open(map, e)
      }
    },
    // 关闭信息窗口
    closeInfoWindow() {
      infoWindow.close()
    },
    // mapzoom方法
    mapZoom() {
      this.resetMapData()
      this.closeInfoWindow()
    },
    // 创建icon方法
    buildIcon(name, zoom) {
      const sizeNum = zoom ? (Number(zoom) / 2) * 4 : 25
      if (name === 'batteryIcon') {
        // 电池图标
        const batteryIcon = new AMap.Icon({
          size: new AMap.Size(sizeNum, sizeNum), // 图标尺寸
          image: '/static/battery.png', // Icon的图像
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(sizeNum, sizeNum) // 根据所设置的大小拉伸或压缩图片
        })
        return batteryIcon
      } else if (name === 'chargeCabinetIcon') {
        // 换电柜图标
        const chargeCabinetIcon = new AMap.Icon({
          size: new AMap.Size(sizeNum, sizeNum), // 图标尺寸
          image: '/static/chargeCabinet.png', // Icon的图像
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(sizeNum, sizeNum) // 根据所设置的大小拉伸或压缩图片
        })
        return chargeCabinetIcon
      } else if (name === 'pointIcon') {
        // 租赁点图标
        const pointIcon = new AMap.Icon({
          size: new AMap.Size(sizeNum, sizeNum), // 图标尺寸
          image: '/static/point.png', // Icon的图像
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(sizeNum, sizeNum) // 根据所设置的大小拉伸或压缩图片
        })
        return pointIcon
      }
    },
    // 后台获取电池经纬度
    async getBatteryData() {
      const lnglatArr = []
      await batteryGpsLastApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            res.data.forEach((item) => {
              if (item.latitude && item.longitude) {
                const lnglat = [String(item.longitude), String(item.latitude)]
                lnglatArr.push({ lnglat, data: item })
              }
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
      return lnglatArr
    },
    // 后台获取换电柜经纬度
    async getChargeCabinetData() {
      const lnglatArr = []
      await chargeCabinetApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            res.data.forEach((item) => {
              if (item.latitude && item.longitude) {
                const lnglat = [String(item.longitude), String(item.latitude)]
                lnglatArr.push({ lnglat, data: item })
              }
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
      return lnglatArr
    },
    // 后台获取门店经纬度
    async getPointData() {
      const lnglatArr = []
      await pointApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            res.data.forEach((item) => {
              if (item.latitude && item.longitude) {
                const lnglat = [String(item.longitude), String(item.latitude)]
                lnglatArr.push({ lnglat, data: item })
              }
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => [this.$message.error(e.message)])
      return lnglatArr
    }
  }
}
</script>

<style lang='less' scoped >
#container {
  height: 100vh;
}
.text-title {
  font-size: 18px;
  font-weight: 500;
  color: #0060aa;
}
.name {
  font-size: 16px;
  font-weight: 400px;
  color: #333;
}
.value {
  font-size: 28px;
  font-weight: 400px;
  font-family: Impact;
  color: #1879b9;
}
.extremum {
  display: inline-block;
  width: 35px;
  height: 10px;
  border-radius: 8px;
  // background-color: green;
  background: linear-gradient(
    1turn,
    #177500,
    #007500 4%,
    #2de900 53%,
    #a7ff9b 84%,
    #07ed10
  );
  margin-right: 8px;
}
.battery-index {
  font-size: 15px;
  font-weight: 800;
  padding-right: 5px;
}
.maps-btn {
  position: relative;
  top: 20px;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  z-index: 11;
  .btn-default {
    color: white;
    background-color: gray;
    border-radius: 50px;
    width: 102px;
    padding: 0 30px;
    border: none;
  }
  .btn-active {
    background-color: #1b65b5;
    color: white;
    width: 102px;
    border-radius: 50px;
    padding: 0 30px;
    // box-shadow: 5px 5px 5px #7038b8;
    border-bottom: 4px #7038b8 solid;
  }
}
.info-box {
  background-color: #1b65b5;
  width: 320px;
  padding: 10px 10px 20px;
  color: white;
  border-radius: 10px;
  opacity: 0.7;
  .closeIcon {
    margin-left: 280px;
    font-size: 15px;
  }
  li {
    list-style: none;
    margin-left: -25px;
    display: flex;
    .text1 {
      width: 84px;
    }
    .text2 {
      flex: 1;
    }
  }
  .btn {
    padding: 4px 15px;
    margin-left: 200px;
    border: 1px solid white;
    border-radius: 15px;
    cursor: pointer;
  }
}
.switch {
  position: relative;
  left: 20px;
  top: 350px;
  z-index: 99;
}
.info-box-right {
  background-color: #1b65b5;
  width: 150px;
  padding: 10px 10px;
  color: white;
  border-radius: 10px;
  opacity: 0.7;
  .closeIcon {
    margin-left: 120px;
    font-size: 15px;
  }
  li {
    list-style: none;
    margin-left: -50px;
    width: 150px;
    display: flex;
    padding: 5px 20px;
    &:hover {
      background-color: gray;
    }
  }
}
</style>
