<template>
  <div>
    <div
      class="battery-wrap"
      :style="`border:5px solid ${backgrounds};`"
      :class="size === 'default' ? 'default-sty-wrap' : 'small-sty-wrap'"
    >
      <div
        class="battery-header"
        :style="`background-color:${backgrounds};`"
        :class="size === 'default' ? 'default-sty-header' : 'small-sty-header'"
      ></div>
      <div
        class="battery-body"
        :style="`width:${
          this.state !== '0' ? this.num : '0px'
        }%;background-color:${
          this.state !== '0' ? this.backgrounds : 'white'
        }; `"
        :class="
          (num <= 20 && state !== '0' ? 'low-battery' : '') +
          ' ' +
          (isShowAnimation === true ? 'animation-battery' : '') +
          ' ' +
          (size === 'default' ? 'default-sty-body' : 'small-sty-body')
        "
      ></div>
      <div
        v-if="state !== '0' && name === 'battery'"
        :style="`${num <= 20 ? 'color:red' : ''}`"
        :class="size === 'default' ? 'default-sty-num' : 'small-sty-num'"
      >
        {{ `${num + '%'} ` }}
      </div>
      <div
        v-if="name === 'vdc'"
        :style="`${vdc !== 'undefine' ? 'color:white;left:20px' : ''}`"
        :class="size === 'default' ? 'default-sty-num' : 'small-sty-num'"
      >
        {{ `${vdc + 'mV'} ` }}
      </div>
      <div
        v-if="name === 'vdc2'"
        :style="`${vdc2 !== 'undefine' ? 'color:white;font-size:16px' : ''}`"
        :class="size === 'default' ? 'default-sty-num' : 'small-sty-num'"
      >
        {{ vdc2 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'battery',
  props: {
    size: {
      type: String,
      default: 'default'
    },
    name: {
      type: String,
      default: 'battery'
    },
    num: {
      // required: true,
      type: Number
    },
    backgrounds: {
      // required: true,
      type: String,
      default: '#0060aa'
    },
    state: {
      type: String
    },
    vdc: {
      type: Number
    },
    vdc2: {
      type: Number
    },
    isShowAnimation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style lang='less' scoped>
@body-width: `this.num`;
.default-sty-wrap {
  width: 120px;
  height: 60px;
}
.default-sty-header {
  position: absolute;
  left: 110px;
  top: 13px;
  width: 10px;
  height: 25px;
  border-radius: 8px;
  background-color: #0060aa;
}
.small-sty-wrap {
  width: 90px;
  height: 45px;
}
.default-sty-num {
  position: absolute;
  font-size: 18px;
  font-weight: 800;
  color: black;
  left: 35px;
  top: 10px;
}
.small-sty-num {
  position: absolute;
  font-size: 14px;
  font-weight: 800;
  color: black;
  left: 20px;
  top: 5px;
}
.small-sty-header {
  position: absolute;
  left: 82px;
  top: 10px;
  width: 8px;
  height: 15px;
  border-radius: 5px;
  background-color: #0060aa;
}
.default-sty-body {
  height: 40px;
  border-radius: 8px;
}
.small-sty-body {
  height: 25px;
  border-radius: 5px;
}
.battery-wrap {
  position: relative;
  border: 5px solid #0060aa;
  border-radius: 10px;
  padding: 5px;
  background-color: white;
  // .battery-body {
  // }
  // .battery-header {
  //   position: absolute;
  //   left: 110px;
  //   top: 13px;
  //   width: 10px;
  //   height: 25px;
  //   border-radius: 8px;
  //   background-color: #0060aa;
  // }
  // .battery-num {
  // }
}
.low-battery {
  background-color: red !important;
}
.animation-battery {
  animation: ui-battery 2s;
}
.battery-animation {
  @keyframes ui-battery {
    from {
      width: 0%;
    }
    to {
      width: @body-width;
    }
  }
}
</style>
